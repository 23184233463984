import React, { useEffect, useState } from "react";
import "../styles/Home.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadphonesAlt } from "@fortawesome/free-solid-svg-icons";

function Home() {
  const [introVisible, setIntroVisible] = useState(introDismissed);
  const [titleAnimationTriggered, setTitleAnimationTriggered] = useState(false);
  const [descriptionAnimationTriggered, setDescriptionAnimationTriggered] =
    useState(false);
  const [imageOpacity, setImageOpacity] = useState({ left: 1, center: 0, right: 0 }); 

  function introDismissed() {
    return localStorage.getItem("introDismissed") !== "true";
  }

  function introTimeout() {
    setIntroVisible(false);
    localStorage.setItem("introDismissed", "true");
    heroTimeout();
  }

  function heroTimeout() {
    setTitleAnimationTriggered(true);
    setTimeout(() => {
      setDescriptionAnimationTriggered(true);
    }, 500);
  }

  useEffect(() => {
    if (introVisible) {
      const introTimer = setTimeout(introTimeout, 3000);
      return () => clearTimeout(introTimer);
    } else {
      heroTimeout();
    }
  }, [introVisible]);

  // Mouse move handler for the opacity effect
  function handleMouseMove(event) {
    const screenWidth = window.innerWidth;
    const mouseX = event.clientX;

    const leftThird = screenWidth / 3;
    const rightThird = (2 * screenWidth) / 3;

    if (mouseX < leftThird) {
      setImageOpacity({ left: 1, center: 0, right: 0 });
    } else if (mouseX < rightThird) {
      setImageOpacity({ left: 0, center: 1, right: 0 });
    } else {
      setImageOpacity({ left: 0, center: 0, right: 1 });
    }
  }

  return (
    <div className="hero-container" onMouseMove={handleMouseMove}>
      {introVisible && (
        <div className="intro-sheet">
          <div className="intro-content">
            <FontAwesomeIcon
              icon={faHeadphonesAlt}
              className="headphones-icon"
            />
            <p>Headphones recommended</p>
          </div>
        </div>
      )}
      <div className="background-image-container">
        {/* Two layered images */}
        <img
          src="/images/youngman.png"
          alt="youngman"
          className="background-image image-left"
          style={{
            opacity: imageOpacity.left
          }}
        />
        <img
          src="/images/oldman.png"
          alt="oldman"
          className="background-image image-right"
          style={{
            opacity: imageOpacity.right, // Increase opacity as the mouse moves right
          }}
        />
        <img
          src="/images/conversation.png"
          alt="oldman"
          className="background-image image-center"
          style={{
            opacity: imageOpacity.center, // Increase opacity as the mouse moves right
          }}
        />
        <div className="hero">
          <div className="hero-content">
            <h1
              className={`title ${
                titleAnimationTriggered ? "slide-fade-in" : ""
              }`}
            >
              Welcome to Midnight Cocktail
            </h1>
            <p
              className={`description ${
                descriptionAnimationTriggered ? "fade-in" : ""
              }`}
            >
              Unfiltered conversations bridging generations in the heart of New
              York City.
            </p>
          </div>
          {/* Buttons Based on Mouse Position */}
          {imageOpacity.left === 1 && (
            <button className="hero-button hero-button-left">Young Host</button>
          )}
          {imageOpacity.center === 1 && (
            <button className="hero-button hero-button-center">Explore Conversations</button>
            )}
          {imageOpacity.right === 1 && (
            <button className="hero-button hero-button-right">Expert Guest</button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
