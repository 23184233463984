// src/components/Footer.jsx
import React from "react";
import "../styles/Footer.css";
import { useLocation } from "react-router-dom";

function Footer() {
  const location = useLocation();
  const isHome = location.pathname === "/";

  return (
    <div className={`footer ${isHome ? "transparent" : ""}`}>
      <p>Made by Guoliang Eric Liu 2024</p>
    </div>
  );
}

export default Footer;
