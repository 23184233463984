// src/components/Team.jsx
import React from "react";
import "../styles/Team.css";

const teamData = [
  {
    name: "Eric Liu",
    role: "Fullstack Engineer",
    bio: "Eric is a senior undergraduate student at NYU pursuing a degree in Computer Science and Psychology",
  },
];

function Team() {
  return (
    <div className="team-container">
      <h2>Meet Our Team</h2>
      <div className="team-grid">
        {teamData.map((member, index) => (
          <div className="team-member" key={index}>
            <div
              className="team-photo"
              style={{ backgroundImage: `url(${member.photo})` }}
            />
            <h3>{member.name}</h3>
            <p>
              <strong>{member.role}</strong>
            </p>
            <p>{member.bio}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Team;
