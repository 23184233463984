// src/components/Mission.jsx
import React from "react";

function Mission() {
  return (
    <div className="mission-container">
      <h2>Our Mission</h2>
      <div className="mission-content">
        <p>Coming soon.</p>
      </div>
    </div>
  );
}

export default Mission;
