import { React, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/Navbar.css";

function Navbar() {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isHome = location.pathname === "/";

    useEffect(() => {
    function checkIsMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android|iPad|iPhone|iPod/i.test(userAgent)) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    checkIsMobile();

    // Optional: Re-check on resize for responsive behavior
    function handleResize() {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className={`navbar ${isHome && !isMobile ? "transparent" : ""}`}>
      <div className="navbar-container">
        <h1 className="logo">Midnight Cocktail</h1>

        {isMobile ? (
          <div>
            <div className="hamburger-menu" onClick={toggleMenu}>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
            <ul className={`nav-links-mobile ${isMenuOpen ? "open" : ""}`}>
              <li>
                <a href="/" className="nav-link" onClick={toggleMenu}>
                  Home
                </a>
              </li>
              <li>
                <a href="/about" className="nav-link" onClick={toggleMenu}>
                  About
                </a>
              </li>
              <li>
                <a href="/team" className="nav-link" onClick={toggleMenu}>
                  Team
                </a>
              </li>
              <li>
                <a href="/mission" className="nav-link" onClick={toggleMenu}>
                  Mission
                </a>
              </li>
            </ul>
          </div>
        ) : (
          // Regular Desktop Navbar
          <ul className="nav-links">
            <li>
              <a href="/" className="nav-link">
                Home
              </a>
            </li>
            <li>
              <a href="/about" className="nav-link">
                About
              </a>
            </li>
            <li>
              <a href="/team" className="nav-link">
                Team
              </a>
            </li>
            <li>
              <a href="/mission" className="nav-link">
                Mission
              </a>
            </li>
          </ul>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
