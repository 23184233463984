// src/components/About.jsx
import React from "react";
import "../styles/About.css";

function About() {
  return (
    <div className="about-container">
      <h2>About Midnight Cocktail</h2>
      <p>Coming soon...</p>
    </div>
  );
}

export default About;
